// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
require("prismjs/themes/prism.css")
require("katex/dist/katex.min.css")

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("../sw.js")
    .then(function() {
      console.log("Service Worker registered successfully")
    })
    .catch(function() {
      console.log("Service worker registration failed")
    })
}
